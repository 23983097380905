/*
 * - #MAIN.JS
 *  ============================================================================
 *   Al Javascript must be added here. This is the last file compiled.
 *   
 *  ============================================================================
*/

// -----------------------------------------------------------------------------
//  The following line gives support for ES6 in JSHint for this file
//  More info: http://jshint.com/docs/options/ 

/* jshint esversion:6 */
// -----------------------------------------------------------------------------






(function(window) {
  
  
  
 


//  VARIABLES  
// -----------------------------------------------------------------------------

  // HTML tag
  const htmlTag = document.querySelector('.js');
  // Body tag
  const bodyTag = document.body;
  // Current Page
  const currentPage = bodyTag.dataset.page;
  // Menu status
  var isMenuOpen = false;
  // Menu Animation status
  var isMenuAnimating = false;
  // Work Overlay open
  var isWorkLoaded = false;
  // Main preloader object
  //var mainPreloader = document.querySelector('.preloader');
  
  
  // Mobile detector
  var isMobile = 
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ?
  true : false ;


 
// FIXES
// -----------------------------------------------------------------------------

  //Fixes Bug on iOS that stops hovered elements from hiding when tapped outside
  if ( isMobile ) { bodyTag.style.cursor = 'pointer'; }
   
  
 

// CSS EVENTS SUPPORT
// ----------------------------------------------------------------------------- 
  
  var animEndEventNames = {
  	'WebkitAnimation' : 'webkitAnimationEnd',
    'OAnimation' : 'oAnimationEnd',
    'msAnimation' : 'MSAnimationEnd',
    'animation' : 'animationend'
  },
  // animation end event name
  animEndEventName = animEndEventNames[ Modernizr.prefixed( 'animation' ) ];
		
  var	transEndEventNames = {
    'WebkitTransition': 'webkitTransitionEnd',
    'MozTransition': 'transitionend',
    'OTransition': 'oTransitionEnd',
    'msTransition': 'MSTransitionEnd',
    'transition': 'transitionend'
  },
  // Transition end event name
  transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ];
  // CSS Support
  var cssSupport = {
    animations : Modernizr.cssanimations, 
    transitions : Modernizr.csstransitions
  };
  
  //Animated elements stoped
  var stopedElements = document.querySelector('.is-stoped') ?
  [].slice.call(document.querySelectorAll('.is-stoped')) : [] ;
  //Animated elements stoped ON LOAD
  var stopedElementsOnload = document.querySelector('.is-stoped--onload') ?
  [].slice.call(document.querySelectorAll('.is-stoped--onload')) : [] ;
  




// PRELOADER
// -----------------------------------------------------------------------------

  function showPreloader() {
    
    var preloader = document.querySelector('.preloader');

		if ( preloader ) {
  		// Add loading state to body
      
  		
			window.addEventListener('load', function() {
  			
  			
  			//Remove loading state from body
        bodyTag.classList.add('loaded');
        preloader.addEventListener(transEndEventName, function() {
          console.log("Preloader faded");
  				preloader.parentNode.removeChild(preloader);
				});

				// Automatically show elements removing stoped state
/*
				setTimeout(function(){
  			  for (var i = 0; i < stopedElementsOnload.length; i++) {
            stopedElementsOnload[i].classList.remove("is-stoped--onload");
          }
				}, 250)
				*/
				
				setTimeout(function(){
					if ( preloader.offsetWidth > 0 && preloader.offsetHeight > 0 ) {
						bodyTag.classList.add('loaded');
            preloader.addEventListener(transEndEventName, function() {
              console.log("Preloader faded");
              preloader.parentNode.removeChild(preloader);
				    });
					}
				}, 5000);
				
			});
		}	
/*
		} else {
		  // Automatically show elements with anim-onload class
		  setTimeout(function(){
		    for (var i = 0; i < stopedElementsOnload.length; i++) {
          stopedElementsOnload[i].classList.remove("is-stoped--onload");
        }
		  }, 250);
		}
*/
	}

	showPreloader();
	
	

//  AJAXING SVG SPRITE - https://css-tricks.com/ajaxing-svg-sprite/ 
// -----------------------------------------------------------------------------

  var ajaxsprite = new XMLHttpRequest();
  ajaxsprite.open("GET", "imgs/sprite.svg", true);
  ajaxsprite.send();
  ajaxsprite.onload = function(e) {
    var sprite = document.createElement("div");
    sprite.style.cssText = "display: none; height: 0px";
    sprite.innerHTML = ajaxsprite.responseText;
    document.body.insertBefore(sprite, document.body.childNodes[0]);
    console.log("Sprite Loaded");
  };




//  OVERLAY   
// -----------------------------------------------------------------------------

  var menuOverlay = document.querySelector('.js-nav-overlay' );
  var mainOverlay = document.querySelector('.js-main-overlay');

	function toggleOverlay(overlay) {
		if( classie.has( overlay, 'open' ) ) {
			classie.remove( overlay, 'open' );
			classie.add( overlay, 'close' );
			var onEndTransitionFn = function( ev ) {
				if( cssSupport.transitions ) {
					if( ev.propertyName !== 'visibility' ) return;
					this.removeEventListener( transEndEventName, onEndTransitionFn );
				}
				classie.remove( overlay, 'close' );
			};
			if( cssSupport.transitions ) {
				overlay.addEventListener( transEndEventName, onEndTransitionFn );
			}
			else {
				onEndTransitionFn();
			}
		}
		else if( !classie.has( overlay, 'close' ) ) {
			classie.add( overlay, 'open' );
		}
	}



//  MENU BUTTON  
// -----------------------------------------------------------------------------
  
  // Menu button
  const menuBtn = document.querySelector('.js-menu-btn');
  
  function menuAction(e) {
    e.preventDefault();
    menuBtn.classList.toggle("is-active");
    if (isWorkLoaded) { 
      toggleOverlay(mainOverlay);
      resetSlider();
    }
    else { 
      toggleOverlay(menuOverlay); 
      console.log("It Works without worksloaded");
    }
  }
  
  menuBtn.addEventListener('click', menuAction, false);
  
  console.log("Current page: "+currentPage);
  

//  WORK SECTION - SLIDERS
// -----------------------------------------------------------------------------
    
  if (currentPage == "work") {
    
    
    // Slider selector
    var workSlider = document.querySelector('.js_slider');
    // Work container selector
    var container = document.querySelector('.js-main-overlay');
    // Work links
    var workLinks = [].slice.call(document.querySelectorAll('.js-works__link'));
    // Total work links
    //var totalLinks = workLinks.length;
    // Set first slide
    var currentSlide = 0;
    // Set first slide Total
    var slidesTotal = 0;
    // Set initial array of items
    var wslides = [];
    // Work Slider
    var wslider;
   
 
    // Work slider object (Lory)
    var workSliderObj = lory(workSlider, {
      slidesToScroll: 1,
      slideSpeed: 600,
      ease: 'cubic-bezier(0.165, 0.84, 0.44, 1)'
    });
    
    console.log("Lory Loaded");

    //Ajax work loader
    var loadWork = function(workUrl) {
      var ajaxworks = new XMLHttpRequest();
      ajaxworks.open("GET", workUrl, true);
      ajaxworks.send();
      ajaxworks.onload = function(e) {
        // Include content
        container.innerHTML = ajaxworks.responseText;
        // Slider 
        wslider = document.querySelector('.js-wslider');
        // Slider items
        wslides = [].slice.call(wslider.querySelectorAll('.js-wslide'));
        // Total slides
        slidesTotal = wslides.length;
        // Documente loaded
        isWorkLoaded = true;
        // Load touch support
        wslider.addEventListener("touchstart", startTouch, false);
        wslider.addEventListener("touchmove", moveTouch, false); 
        
      };
    };
    
   //Work links
    workLinks.forEach(function(item) {
      var workUrl = item.getAttribute('href');
      console.log(workUrl);
      
      item.addEventListener('click', function(ev) {
        ev.preventDefault();
        menuBtn.classList.toggle("is-active");
        toggleOverlay(mainOverlay);
        loadWork (workUrl);
      });

    });

    
    console.log("Links Works function loaded");
    
    // Inital touch X
    var touchX = null;
        
    var startTouch = function (e) { touchX = e.touches[0].clientX ; };
       
    var moveTouch = function (e) {
      if (touchX === null) { return; }
      var currentX = e.touches[0].clientX;
      var diffX = touchX - currentX;
      if (diffX > 0) { slideNav('next'); } 
      else { slideNav('prev'); } 
      touchX = null;
      e.preventDefault();
    };
    
    console.log("Touch function loaded");
    
  
     // Reset the slider
    var resetSlider = function () {
      isWorkLoaded = false;
      container.removeChild(wslider);
      currentSlide = 0;
      console.log("Work slider reset");
    };

    // Navigation Control
    var slideNav = function (dir) {
      // Enable & Disable Control
      var disabled = dir === 'next' ?
        currentSlide == slidesTotal-1 ? true : false 
      : currentSlide == 0 ? true : false ;
      console.log("Navigation disabled");
      
      // Add & Remove "active" Class
      if (!disabled) {
        for (var i = 0; i < slidesTotal; i++) {
          wslides[i].classList.remove("is-active");
        }
        currentSlide = dir === 'next' ? 
          currentSlide < slidesTotal-1 ? currentSlide + 1 : 0
        : currentSlide > 0 ? currentSlide - 1 : slidesTotal-1;
        
        wslides[currentSlide].classList.add("is-active");
      }
    };
    
   
     // Buttons
    var nextBtn = document.querySelector('.js-next');
    var prevBtn = document.querySelector('.js-prev');
    

    nextBtn.addEventListener('click', function (e) {
      e.preventDefault();
      if (isWorkLoaded){ slideNav('next'); }
      else { workSliderObj.next(); }
    });
    prevBtn.addEventListener('click', function (e) {
      e.preventDefault();
      if (isWorkLoaded) { slideNav('prev'); }
      else { workSliderObj.prev(); }
    });

 }


 
 
  
/*  
 

	
	
	
// CONTACT FORM
//------------------------------------------------------------------------------
	
	if (currentPage == "contact") {
  	// Get the form.
    var contactForm = document.querySelector('.js-c-form');
    // Get the submit button.
    var submitBtn = document.querySelector('.js-c-form__submit');
    // Get the messages div.
    var contactFormResp = document.querySelector('.js-c-form__resp');
  
    contactForm.onsubmit = function(e){
      
      //e.preventDefault();
      
      var formData = serialize(contactForm);
      
      var request = new XMLHttpRequest();
      
      request.open('POST', 'bin/mailer.php', true);
      
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
      
      request.onload = function() {
        var resp = request.responseText;
        if (request.status >= 200 && request.status < 400) {
          // Success!
          classie.toggle( contactFormResp, 'success' );
          contactFormResp.innerHTML = resp;
          contactForm.reset();
          setTimeout(function() { classie.toggle( contactFormResp, 'success' ); }, 4000);
          
        } else {
        // Reached target server, but it returned an error
          classie.toggle( contactFormResp, 'error' );
          contactFormResp.innerHTML = resp;
          setTimeout(function() { classie.toggle( contactFormResp, 'error' ); }, 4000);
        }
      };
  
      request.onerror = function() {
        // There was a connection error of some sort
        classie.toggle( contactFormResp, 'error' );
        contactFormResp.innerHTML = "There was a connection error";
        setTimeout(function() { classie.toggle( contactFormResp, 'error' ); }, 4000);
      };
      
      request.send(formData);
    };
  }
*/


  	
})(window);




//  GOOGLE MAP  
// -----------------------------------------------------------------------------

function initMap() {
	var ivgroup = {lat: 25.700501, lng: -80.285702};
	var map = new google.maps.Map(document.getElementById('gmap'), {
		zoom: 15,
		center: ivgroup,
		styles:
			[
				{
				  "featureType": "landscape",
				  "elementType": "geometry.fill",
				  "stylers": [  { "color": "#FBFBFC" } ]
				},
				{
  				"featureType": "administrative",
				  "elementType": "labels.text.fill",
				  "stylers": [ { "color": "#97A5BF" } ]
				},
				{
				  "featureType": "poi",
				  "elementType": "geometry",
				  "stylers": [  { "color": "#F0F2F7" } ]
				},
				{
				  "featureType": "poi.business",
				  "elementType": "all",
				  "stylers": [  { "visibility": "off" } ]
				},
				{
				  "featureType": "poi.park",
				  "elementType": "all",
				  "stylers": [  { "visibility": "off" } ]
				},
				{
				  "featureType": "poi.medical",
				  "elementType": "all",
				  "stylers": [  { "visibility": "off" } ]
				},
				{
				  "featureType": "poi.sports_complex",
				  "elementType": "all",
				  "stylers": [  { "visibility": "off" } ]
				},
				{
				  "featureType": "poi",
				  "elementType": "labels.text.fill",
				  "stylers": [ { "color": "#97A5BF" } ]
				},
				{
				  "featureType": "road",
				  "elementType": "geometry",
				  "stylers": [ { "color": "#F7F8FA" } ]
				},
				{
				  "featureType": "road",
				  "elementType": "labels.text.fill",
				  "stylers": [  { "color": "#CED4E1" } ]
				},
				{
				  "featureType": "water",
				  "elementType": "geometry.fill",
				  "stylers": [ { "color": "#EAEDF2" } ]
				},
				{
  				"featureType": "water",
				  "elementType": "labels.text.fill",
				  "stylers": [ { "color": "#97A5BF" } ]
				},
				{
  				"featureType": "transit",
				  "elementType": "labels.text.fill",
				  "stylers": [ { "color": "#97A5BF" } ]
				}
			]		
  });
  var iconMarker = 'imgs/icon-marker.svg';
  var marker = new google.maps.Marker({
    position: ivgroup,
    map: map,
    icon: iconMarker
  });
}

	
	








